.b-app {
    display: block;
    background-image: url("../media/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
}

.b-cdn {
    position: fixed;
    top: 35vh;
    left: 10vw;
    width: 80vw;
    height: 30vh;
    margin: auto;
    font-family: "Exo";
    text-align: center;
    color: rgba(245, 245, 245, 0.925);
}

@media only screen and (max-width: 580px) {
    .b-cdn {
        top: 15vh;
        height: 30vh;
    }
}

.b-cdn ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.b-cdn-elmnt {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 80px;
    margin-left: 5vw;
    margin-right: 5vw;
    transition: transform 0.15s ease;
    text-shadow: 1px 1px #ff0000;
}

.b-cdn-elmnt-flash {
    animation: color-fade 0.6s infinite ease-out;
}

.b-cdn-elmnt:hover {
    transform: scale(1.2);
}

.b-cdn-elmnt-num {
    font-size: 60px;
    font-weight: 800;
}

.b-cdn-elmnt-unit {
    font-size: 20px;
    font-weight: 200;
}

.b-cdn-text {
    margin-top: 5vh;
    font-family: "Mountains of Christmas";
    font-size: 55px;
    font-weight: 800;
    text-transform: uppercase;
    color: rgb(196, 10, 10);
}

.b-cdn-text-shake {
    animation-name: horizontal-shaking;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes horizontal-shaking {
    0% {
        transform: translateX(0)
    }

    5% {
        transform: translateX(5px)
    }

    10% {
        transform: translateX(-5px)
    }

    15% {
        transform: translateX(5px)
    }

    20% {
        transform: translateX(0)
    }
}

@keyframes color-fade {
    0% {
        color: rgba(245, 245, 245, 0.925);
    }

    10% {
        color: rgb(196, 10, 10);
    }

    100% {
        color: rgba(245, 245, 245, 0.925);
    }
}