@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@700&display=swap');

body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-scrollbar: pseudo-selector;
  scrollbar-width: none;
  user-select: none;
}

#root {
  height: 100vh;
  width: 100vw;
}